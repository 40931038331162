import Styles from './Text.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Text = ({
  element: Elem = 'p',
  children,
  size = Styles.body,
  color = Styles.black,
  type = Styles.standard,
  className,
  ...restProps
}) => {
  return (
    <Elem className={classNames(Styles.base, size, type, color, className)} {...restProps}>
      {children}
    </Elem>
  );
};

/* 
size={Text.Size.HEADLINE}
color={Text.Color.WHITE}
*/

Text.Styles = Styles;

Text.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.string,
  type: PropTypes.string,
  element: PropTypes.node,
};

Text.Type = {
  PRIMARY: Styles.primary,
  SECONDARY: Styles.secondary,
  NORMAL: Styles.standard,
  WHITE: Styles.white,
  PARAGRAPH: Styles.paragraph,
  CENTER: Styles.center,
};

Text.Color = {
  BLACK: Styles.black,
  BLUE: Styles.blue,
  DARK_BLUE: Styles.darkblue,
  BLOGBLUE: Styles.blogBlue,
  WHITE: Styles.white,
  GREY: Styles.grey,
};

Text.Size = {
  HEADLINE: Styles.headline,
  SUBHEADLINE: Styles.subheadline,
  SUBHEADLINE_LARGE: Styles.subheadlineLarge,
  HEADING_TWO: Styles.headingTwo,
  BODY: Styles.body,
  DISPLAY: Styles.display,
  H2: Styles.h2,
  H3: Styles.h3,
  H4: Styles.h4,
  H5: Styles.h5,
};

export default Text;
