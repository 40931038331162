import { Suspense } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Head from 'next/head';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
// import Footer from '@components/Footer/Footer';
// import { PopupType } from '@hiredigital/lib/helpers/marketingEnum';
// import { ParallaxProvider } from 'react-scroll-parallax';

import { defaultHead } from './defaults.js';
import {
  getMetaContent,
  generateSchema,
  generateSocial,
  generateProd,
  generateDev,
  cleanPageHead,
  convertPropsToHeadElements,
  generateFaqSchema,
} from './utils.jsx';

// import Header from '@components/Header/Header';
import { Types, Variants } from '@components/Header/constants';

import HeaderStyles from '@components/Header/Header.module.scss';
const Popup = dynamic(() => import('@components/Popup/Popup'), { suspense: true });
// const Announcement = dynamic(() => import('@components/Announcement/Announcement'), {
//   suspense: true,
// });
const Footer = dynamic(() => import('@components/Footer/Footer'));

const PopupType = {
  FULL_PAGE_MODAL: 'full',
  BOTTOM_PAGE_MODAL: 'bottom',
};

const MainLayout = ({
  header: Header,
  footerData,
  locale,
  footer,
  darkFooter,
  children,
  hasHero = true,
  pageHead: origPageHead,
  isPost = false,
  postSchema,
  variant = Variants.DEFAULT,
  type = Types.DARK,
  showHeaderBackground,
  buttonLabel,
  desktopMatType = PopupType.BOTTOM_PAGE_MODAL,
  analyticsData,
  faqData,
  canonicalUrl: initialCanonical,
}) => {
  const router = useRouter();
  const pageHead = cleanPageHead(origPageHead);
  var currentPath = router.asPath.split('?')[0];
  const pageUrl = process.env.NEXT_PUBLIC_SITE_URL + currentPath;
  const canonicalUrl = initialCanonical || process.env.NEXT_PUBLIC_CANONICAL_URL + currentPath;
  // const data = useLayoutQuery();
  // Note that the only one cheat we have is to allow a single image meta but it does not exist
  const commonMeta = {
    title: isPost
      ? getMetaContent((pageHead, 'og:title')) || pageHead?.title || defaultHead.title
      : pageHead?.title || defaultHead.title,
    image: getMetaContent(pageHead, 'image') || getMetaContent(defaultHead, 'image'),
    description:
      getMetaContent(pageHead, 'description') || getMetaContent(defaultHead, 'description'),
    url: pageUrl,
  };

  const socialHead = generateSocial(commonMeta, isPost);
  const envHead = process.env.NEXT_PUBLIC_ENV === 'prod' ? generateProd() : generateDev();
  const schemaFaq = generateFaqSchema(faqData);
  const schemaData = generateSchema(commonMeta, postSchema, schemaFaq, isPost);

  let bodyClass;
  switch (variant) {
    case MainLayout.Variants.ENTERPRISE:
      bodyClass = HeaderStyles.enterprise;
      break;
    case MainLayout.Variants.INSIGHTS:
      bodyClass = HeaderStyles.insights;
      break;
    default:
      bodyClass = HeaderStyles.main;
  }

  return (
    <>
      <Head>
        {convertPropsToHeadElements(defaultHead)}
        {convertPropsToHeadElements(pageHead)}
        {convertPropsToHeadElements(socialHead)}
        {convertPropsToHeadElements(envHead)}
        <link rel='canonical' href={canonicalUrl} />
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: schemaData }}></script>
      </Head>
      {/*<Suspense><Announcement /></Suspense>*/}
      <Header
        showBackground={showHeaderBackground}
        variant={variant}
        buttonLabel={buttonLabel}
        analyticsData={analyticsData}
      />

      <div className={classNames(bodyClass, hasHero && HeaderStyles.hasHero)}>{children}</div>
      <Footer
        footer={footer}
        darkFooter={darkFooter}
        data={footerData}
        analyticsData={analyticsData}
      />
      <Suspense>
        <Popup type={desktopMatType} analyticsData={analyticsData} />
      </Suspense>
    </>
  );
};

MainLayout.Variants = Variants;
MainLayout.Types = Types;
MainLayout.desktopMatType = PopupType;

// headerMenuType
MainLayout.propTypes = {
  footerData: PropTypes.object,
  children: PropTypes.node,
  hasHero: PropTypes.bool,
  darkFooter: PropTypes.bool,
  pageHead: PropTypes.object,
  isPost: PropTypes.bool,
  variant: PropTypes.number,
  type: PropTypes.string,
  buttonLabel: PropTypes.string,
  desktopMatType: PropTypes.string,
  analyticsData: PropTypes.object,
  faqData: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          html: PropTypes.string,
          text: PropTypes.string,
        }),
      ]),
    })
  ),
};

export default MainLayout;
