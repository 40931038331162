import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Link from 'next/link';
import { rewrites } from '@hiredigital/lib/rewrites';

// Custom Link component that offers backwards compatibility with GatsbyJS Link component

const CustomLink = ({
  to,
  href,
  as,
  shallow = false,
  children,
  className,
  activeClassName,
  ...props
}) => {
  const h = href || to;
  const router = useRouter();
  const isRewrite = rewrites.includes(h);

  const handleClick = (event) => {
    if (isRewrite) {
      event.preventDefault();
      window.location.href = h;
    }
  };

  return (
    <Link
      href={h}
      as={as}
      shallow={shallow}
      prefetch={false}
      onClick={handleClick}
      className={classNames(className, router.asPath === h && activeClassName)}
      {...props}>
      {children}
    </Link>
  );
};

CustomLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
};

export default CustomLink;
