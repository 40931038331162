// All rewrites to HTML pages from "marketing" next.config.js should be added here.
// If the link is a rewrite to an HTML page, using next/link will cause a 404 error.
// (This is a problem with the Pages router, but won't be fixed. It is fixed in App router.)
// Instead, we should navigate to the page directly using the browser navigation.
export const rewrites = [
  // '/.wf_auth', // 404
  '/marketing-organizations-survey-report-2021',
  '/why',
  '/tools/glassmorphism-css-generator',
  // '/skills', // Replaced by pages/skills/index.jsx
  // '/enterprise/company', // 404
  // '/clients', // Replaced by pages/clients/index.jsx
  '/events',
  '/events/apac-roundtable-48fd3dd8',
  '/events/digital-leaders-roundtable-2022',
  '/events/marketing-transformation-for-financial-services-roundtable',
  '/case-studies',
  // if (process.env.NODE_ENV === 'production')
  '/enterprise/company/3m',
  '/enterprise/company/generic-account-based-page-redesign-apr22',
  '/enterprise/company/on-demand-teams',
  '/enterprise/company/organon',
  '/enterprise/company/reckitt-on-demand-teams',
  '/enterprise/company/template-company-page',
  '/enterprise/company/unilever-on-demand-teams',
  '/enterprise/company/veeam',
];
