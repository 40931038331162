import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Loader.module.scss';

const Loader = memo(
  ({ className, size, color = Styles.blue, type, isError, isCompleted = false, ...props }) => {
    return (
      <div className={classNames(Styles.loader, className, size, color, type)} {...props}>
        {!isError && !isCompleted && <div className={Styles.spinner} />}
        {isCompleted && <div className={classNames(Styles.checkmark)} />}
        {isError && <div className={classNames(Styles.error)} />}
      </div>
    );
  }
);

Loader.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  isCompleted: PropTypes.bool,
  isError: PropTypes.bool,
};

Loader.Color = {
  WHITE: Styles.white,
  BLUE: Styles.blue,
};

Loader.Size = {
  LARGE: Styles.large,
  SMALL: Styles.small,
};

Loader.Type = {
  INLINE: Styles.inline,
  FULL: Styles.full,
};

Loader.Styles = Styles;

export default Loader;
