import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from '@components/Link';
import Styles from './Styles.module.scss';

const LatestPost = ({ latestPost }) => {
  return (
    <Link
      className={Styles.latestContainer}
      href={`/blog/${latestPost.slug}`}
      title={latestPost.title}>
      <div className={Styles.firstContainer}>
        <div className={Styles.firstContent}>
          <div className={Styles.firstNote}>{`Latest Post`}</div>
          <h1 className={Styles.firstTitle}>{latestPost.title}</h1>
          <div className={Styles.firstTags}>
            {latestPost.tags?.nodes?.slice(0, 2).map((tag, j) => (
              <div key={j} className={Styles.firstTag}>
                {tag.name}
              </div>
            ))}
          </div>
          <div className={Styles.firstMore}>{`Read More`}</div>
        </div>
        <div className={Styles.firstImageContainer}>
          <Image
            title={latestPost.title}
            alt={latestPost.title}
            className={Styles.image}
            sizes='(max-width: 640px) 384px, 640px'
            width={latestPost.featuredImage?.node?.mediaDetails?.width || 1280}
            height={latestPost.featuredImage?.node?.mediaDetails?.height || 720}
            src={latestPost.featuredImage?.node?.sourceUrl}
            priority
          />
        </div>
      </div>
    </Link>
  );
};

LatestPost.propTypes = {
  latestPost: PropTypes.object,
};

export default LatestPost;
